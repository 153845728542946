import React from 'react';
import { Container, Pagination } from 'react-bootstrap';

const Pager = ({ pageContext, page }) => {
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `/${page}/` : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();
  return (
    <Container>
      <Pagination className="justify-content-center">

        {!isFirst && (
          <Pagination.Prev href={prevPage} className="a-pagination">← Vorige</Pagination.Prev>
        )}

        {numPages !== 1 && (
          Array.from({ length: numPages }, (_, i) => (
            <Pagination.Item className="span-pagination" active={i + 1 === currentPage} key={`pagination-number${i + 1}`} href={`/${page}/${i === 0 ? "" : i + 1}`}>
              {i + 1}
            </Pagination.Item>
          )))}

        {!isLast && (
          <Pagination.Next href={nextPage} className="a-pagination">Volgende →</Pagination.Next>
        )}

      </Pagination>
    </Container>
  );
};

export default Pager;