import React from 'react';
import StyledHero from '../components/StyledHero';
import { graphql } from 'gatsby';
import PageWrapper from '../components/Layout/PageWrapper';
import Weekmenu from '../components/Weekmenu/Weekmenu';
import Title from '../components/Title';
import { Container, Col, Row } from 'react-bootstrap';
import Pager from "../components/Pager";
import SEO from "../components/seo";

export default class WeekmenuIndexPage extends React.Component {
    render() {
        const heroImage = this.props.data.weekmenuBcg.childImageSharp.fluid;
        const weekmenus = this.props.data.weekmenus.nodes;
        return (
            <PageWrapper>
                <SEO title="Weekmenu's" />
                <StyledHero img={heroImage} />
                <Container fluid>
                    <Title title="mijn" subtitle="weekmenu's" />
                    <Container fluid className="py-3" >
                        <Row className="d-flex justify-content-center">
                            {weekmenus.map((menu) => {
                                return (
                                    <Col xs={10} sm={6} md={4} lg={3} className="pb-4" key={menu.id} >
                                        <Weekmenu weekmenu={menu} />
                                    </Col>
                                )
                            })}
                        </Row>
                    </Container>
                    <Pager pageContext={this.props.pageContext} page="weekmenu" />
                </Container>
            </PageWrapper>
        )
    }
};

export const query = graphql`
query weekmenuListQuery($skip: Int!, $limit: Int!) {
    weekmenuBcg: file(relativePath: {eq: "weekmenusBcg.jpg"}) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weekmenus: allContentfulWeekmenu(
        sort: {fields: date, order: DESC}
        skip: $skip
        limit: $limit) {
            nodes {
                id
                slug
                title
                featuredImage {
                    fluid(quality: 100, maxHeight: 750) {
                        srcWebp
                    }
                    title
                }
            }
        }
    } 
`;
